import React from 'react'
import Typewriter from 'typewriter-effect';
import bgJumbo from '../../../images/bgJumbo.png'

import ReactGA from 'react-ga';


export default function Jumbotron() {

    const handleReqInfo =()=>{
        window.location.href = 'mailto:halo@balesin.id'
        
        ReactGA.event({
            category: 'jumbotron section',
            action: 'request info',
            label: 'button'
          });
    }
    return (
        <section className='jumbotron bg-white bg-md-light'>
            <div className="container">
                <img src={bgJumbo} alt="" className='bgjumbo' />
                <div className=" wrapcon d-flex align-items-center">
                    <div className='jumbocon' data-aos="fade-up" data-aos-duration="1500">
                        <h1> SAMBIL CHATTING BISA
                            <br />
                            <Typewriter
                                options={{
                                    strings: [
                                        'BELANJA',
                                        'DAPAT REKOMENDASI',
                                        'NGOBROL 24/7',
                                        'DAPAT VOUCHER',
                                        'NGOBROL SAMA EXPERT',
                                    ],
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </h1>
                        <h5 className='mb-3'>Balesin by BJtech siap bantu jangkau langsung pelangganmu secara otomatis</h5>
                        <button
                            className="btn-primary"
                            onClick={handleReqInfo}
                        >
                            REQUEST INFO
                        </button>
                    </div>
                </div>
            </div>

        </section>
    )
}
