import { useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";

import balesinLogo from "../../../images/balesinLogo.png";
import { motion } from "framer-motion";

import ReactGA from "react-ga";

export default function Navbar() {
  const [isOpen, setOpen] = useState(false);
  const variants = {
    open: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      // rotate: [0, -30, 0],
      x: 0,
      transition: {
        type: "tween",
      },
    },
    close: {
      x: "100vw",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: {
        delay: 0.5,
        // when: "beforeChildren", //use this instead of delay
        // staggerChildren: 1, //apply stagger on the parent tag
      },
    },
  };

  const listVariant = {
    hidden: {
      x: -10, //move out of the site
      opacity: 0,
    },
    visible: {
      x: 0, // bring it back to nrmal
      opacity: 1,
      transition: {
        delay: 0.5,
        // when: "beforeChildren", //use this instead of delay
        // staggerChildren: 1, //apply stagger on the parent tag
      },
    },
  };

  return (
    <div className="navbar-custom">
      <div className="container h-100 container d-flex justify-content-between align-items-center">
        <Link className="" to="/">
          <img className="logo" src={balesinLogo} alt="logo" />
        </Link>
        <div className="links d-none d-md-block">
          <Link className="animlink" to="/">
            <p className="mb-1">Home</p>{" "}
          </Link>
          <a className="animlink" href="mailto:halo@balesin.id">
            <p className="mb-1">Contact</p>{" "}
          </a>

          {/* <button
                        className="btn-primary"
                        onClick={() =>{
                            window.location.href = 'https://faq.balesin.id/authentication'
                            ReactGA.event({
                                category: 'navbar section',
                                action: 'try it now',
                                label: 'button'
                              });
                        } 
                        }>
                        TRY IT NOW!
                    </button> */}
        </div>

        {/* MOBILE /////////////////////////// */}
        {/* MOBILE /////////////////////////// */}

        <div className="d-block d-md-none burger">
          <Hamburger size={20} toggled={isOpen} toggle={setOpen} />
        </div>
        <motion.div
          className="mob-menu d-md-none d-flex justify-content-center align-items-center"
          variants={variants}
          animate={isOpen ? "open" : "close"}
          initial="close"
        >
          <motion.ul
            variants={listVariant}
            animate={isOpen ? "visible" : "hidden"}
          >
            <li>
              <Link className="animlink" onClick={() => setOpen(false)} to="/">
                Home
              </Link>
            </li>
            <li>
              <a
                className="animlink"
                onClick={() => setOpen(false)}
                href="mailto:halo@balesin.id"
              >
                Contact{" "}
              </a>
            </li>
            {/* <li>
                                            <Link className='animlink' to='/privacy'>Kebijakan Privasi</Link>
                                        </li>
                                        <li>
                                            <Link className='animlink' to='/term'>Syarat & Ketentuan</Link>
                                        </li> */}
            <li>
              {/* <button
                className="btn-primary"
                onClick={() =>
                  (window.location.href =
                    "https://faq.balesin.id/authentication")
                }
              >
                TRY IT NOW!
              </button> */}
            </li>
          </motion.ul>
        </motion.div>
        {/* MOBILE /////////////////////////// */}
        {/* MOBILE /////////////////////////// */}
      </div>
    </div>
  );
}
