export const privacyHeader = {
    label: 'KEBIJAKAN PRIVASI',
    content: `
    <p> 
    Kebijakan Privasi adalah komitmen dari BJtech untuk menghargai dan melindungi setiap data atau informasi pribadi pengguna layanan BALESIN. Dengan mengakses dan/atau mempergunakan layanan BALESIN, pengguna menyatakan bahwa setiap data pengguna merupakan data yang benar dan sah, serta pengguna memberikan persetujuan kepada BJtech untuk memperoleh, mengumpulkan, mengelola, dan mempergunakan data tersebut sebagaimana tercantum dalam Kebijakan Privasi maupun Syarat & Ketentuan BJtech.
    </p>
    <p>
    Kebijakan Privasi ini bertujuan untuk membantu pengguna memahami data yang dikumpukan oleh BJtech, alasan BJtech mengumpulkannya, dan apa yang BJtech lakukan dengan data tersebut. Semua data yang pengguna berikan akan disimpan BJtech sebaik-baiknya dengan memperhatikan faktor keamanan.
    </p>
    `
}

export const privacyAccordion = [
    {
        label: 'PEROLEHAN DAN PENGUMPULAN DATA',
        content: `
        <p>BJtech mengumpulkan data pengguna untuk memudahkan proses transaksi, mengelola, dan memperlancar proses penggunaan situs, serta tujuan-tujuan lainnya selama diinzinkan oleh peraturan dan/atau undang-undang yang berlaku. Data pengguna yang dikumpulkan adalah:</p>
        <p>1. Data yang diberikan oleh pengguna termasuk namun tidak terbatas pada:</p>
        <ol>
            <li>
                <p>Nama pengguna</p>
            </li>
            <li>
                <p>Informasi kontak pengguna (Alamat email, nomor handphone)</p>
            </li>
            <li>
                <p>Nama usaha atau toko online pengguna</p>
            </li>
            <li>
                <p>Informasi usaha atau toko online pengguna</p>
            </li>
            <li>
                <p>Data-data pembayaran pada saat pengguna melakukan aktivitas transaksi pembayaran melalui platform BJtech, termasuk namun tidak terbatas pada data rekening bank, kartu kredit, virtual account, instant payment, internet banking.</p>
            </li>
        </ol>

        <p>2. Data yang terekam saat pengguna menggunakan layanan BALESIN, termasuk namun tidak terbatas pada:</p>
        <ol>
            <li>
                <p>Data lokasi riil atau perkiraannya seperti alamat IP</p>
            </li>
            <li>
                <p>Data berupa waktu dari setiap aktivitas pengguna, termasuk aktivitas pendaftaran, login, dan lain sebagainya</p>
            </li>
            <li>
                <p>Data penggunaan atau preferensi pengguna, di antaranya interaksi pengguna dalam menggunakan situs platform BJtech, pilihan yang disimpan, serta pengaturan yang dipilih.</p>
            </li>
            <li>
                <p>Data catatan/log, di antaranya catatan pada server yang menerima data seperti alamat IP perangkat, tanggal dan waktu akses, fitur aplikasi atau laman yang dipilih.</p>
            </li>
        </ol>
        `
    },
    {
        label: 'PENGGUNAAN DATA',
        content: `
        <p>BJtech dapat menggunakan sebagian atau keseluruhan data yang diperoleh dan dikumpulkan dari pengguna sebagaimana disebutkan untuk memproses segala bentuk permintaan ataupun aktivitas yang dilakukan oleh pengguna melalui situs. Dengan data dari pengguna, BJtech terus memberikan, mewujudkan, memelihara, dan memperbaiki produk dan layanan untuk membantu memenuhi kebutuhan pengguna.</p>
        <p>BJtech juga mungkin mengirimkan email promosi tentang penawaran baru atau informasi penting lainnya kepada pengguna secara berkala dengan menggunakan alamat email yang pengguna berikan. Serta melakukan kegiatan internal yang diperlukan untuk menyediakan layanan pada BALESIN, seperti pemecahan masalah bug, software, permasalahan operasional, melakukan analisis data, pengujian dan penelitian, dan untuk memantau dan menganalisis kecenderungan penggunaan dan aktivitas.</p>
        `
    },
    {
        label: 'PENGUNGKAPAN DATA PENGGUNA',
        content: `<p>BJtech menjamin tidak akan menjual, mengalihkan, mendistribusikan, dan/atau membuka informasi dan data-data pribadi pengguna kepada orang lain dan/atau pihak ketiga yang tidak berkepentingan tanpa persetujuan dari pengguna.</p>`
    },
    {
        label: 'COOKIES',
        content: `
        <p>Cookies adalah file kecil yang secara otomatis akan tersimpan dalam perangkat komputer pengguna untuk mengidentifikasi dan memantau koneksi jaringan, sehingga memungkinkan pengguna untuk mengakses layanan BALESIN secara optimal. Cookies tersebut tidak diperuntukkan untuk digunakan pada saat melakukan akses informasi atau data lain yang pengguna miliki di perangkat komputer, selain dari yang telah pengguna setujui untuk disampaikan.</p>
        <p>Walaupun secara otomatis perangkat komputer pengguna akan menerima cookies, pengguna dapat menentukan pilihan untuk melakukan modifikasi melalui pengaturan atau setting browser yaitu dengan memilih untuk menolak cookies.</p>
        `
    },
    {
        label: 'PENYIMPANAN DAN PENGHAPUSAN INFORMASI',
        content: `<p>BJtech akan menyimpan informasi selama akun pengguna tetap aktif dan dapat melakukan penghapusan sesuai dengan ketentuan peraturan hukum yang berlaku.</p>`
    },
    {
        label: 'HUKUM',
        content: `<p>Syarat-syarat dan ketentuan dalam Kebijakan Privasi ini tunduk kepada hukum di wilayah Negara Kesatuan Republik Indonesia.</p>`
    },
    {
        label: 'PEMBARUAN KEBIJAKAN PRIVASI',
        content: `<p>BJtech dapat sewaktu-waktu melakukan perubahan atau pembaruan terhadap Kebijakan Privasi ini. BJtech menyarankan agar pengguna membaca secara seksama dan memeriksa halaman Kebijakan Privasi ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan layanan Situs maupun layanan BJtech lainnya, maka Pengguna dianggap menyetujui perubahan-perubahan dalam Kebijakan Privasi.</p>`
    },
]