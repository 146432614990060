
import React, { useState } from 'react'
import Collapsible from 'react-collapsible';
import { BsChevronDown } from "react-icons/bs";
import Layout from '../shared/components/Layout'
import { privacyAccordion, privacyHeader } from './components/privacyAndPolice'




export default function PrivacyContainer() {
    const [activeKey, setActiveKey] = useState(null)

    const handleTrigger = (v) => {
        if (v === activeKey) {
            setActiveKey(null)
        } else {
            setActiveKey(v)
        }
    }


    return (
        <Layout>
            <div className="term privacy">
                <div className="bg-light">
                    <div className="container py-main pb-0 justify-content-center d-flex">
                        <div className="col-md-9 ">
                            <h1 className="text-center mb-5">PRIVACY POLICY FOR BALESIN</h1>

                            <h6 className='mb-4' dangerouslySetInnerHTML={{ __html: privacyHeader.label }} />
                            <div className='mb-5' dangerouslySetInnerHTML={{ __html: privacyHeader.content }} />
                        </div>


                    </div>
                </div>

                <div className="bg-white">
                    <div className="container py-5 justify-content-center d-flex">
                        <div className="col-md-9 ">

                            {
                                privacyAccordion.map((res, i) => (
                                    <Collapsible
                                        open={activeKey === i ? true : false}
                                        className='mb-3'
                                        handleTriggerClick={() => handleTrigger(i)}
                                        key={i}
                                        trigger={
                                            <div className='d-flex justify-content-between border-bottom pe-3'>
                                                <h6 dangerouslySetInnerHTML={{ __html: res.label }} />

                                                <BsChevronDown style={{
                                                    transform: `rotate(${activeKey === i ? '180deg' : '0'})`,
                                                    transition: '.3s'
                                                }} />

                                            </div>
                                        }
                                    >
                                        <div className='bg-white p-3 mb-3' dangerouslySetInnerHTML={{ __html: res.content }} />
                                    </Collapsible>
                                ))
                            }

                        </div>


                    </div>
                </div>
            </div>
        </Layout>
    )
}
