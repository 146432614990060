import React from 'react'
import image360 from '../../../images/image360.svg'

export default function Our360() {
    return (
        <section className="our360 py-main bg-white">
            <div className="container">
                <h2 className="text-center mb-3 mb-md-5" data-aos="fade-up">OUR 360° SOLUTIONS</h2>
                <div className="w-100 text-center d-flex justify-content-center">
                    <div className=" box d-flex justify-content-center align-items-center">
                        <h6 className='chat' data-aos-offset='0' data-aos-delay='500' data-aos-duration='500' data-aos="zoom-in-left">Chat Commerce</h6>
                        <h6 className='market' data-aos-offset='0' data-aos-delay='500' data-aos-duration='500' data-aos="zoom-in-right">Marketing</h6>
                        <h6 className='cus' data-aos-offset='0' data-aos-delay='500' data-aos-duration='500' data-aos="zoom-in-down">Customer Service</h6>
                        <img src={image360} data-aos-duration='1000' data-aos="zoom-in" alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
