import React from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";

import klinikkecil from "../../../images/logoUKM/klinikkecil.png";
import gelora from "../../../images/logoUKM/gelora.png";
import sedjuk from "../../../images/logoUKM/sedjuk.png";
import luberger from "../../../images/logoUKM/luberger.png";
import lawless from "../../../images/logoUKM/lawless.jpeg";
import tuku from "../../../images/logoUKM/tuku.png";
import mangkokku from "../../../images/logoUKM/mangkokku.png";
import { BsWhatsapp } from "react-icons/bs";

export default function ClientSlider() {
  const logos = [
    { logo: klinikkecil, link: "http://wa.me/628118033786" },
    { logo: gelora, link: "http://wa.me/628159333655" },
    { logo: sedjuk, link: "http://wa.me/6281119182525" },
    { logo: luberger, link: "http://wa.me/6288213138484" },
    { logo: lawless, link: "http://wa.me/6287859165037?text=halo--%3E" },
    { logo: tuku, link: "http://wa.me/6281119227433?text=halo%20tuku" },
    {
      logo: mangkokku,
      link: "http://wa.me/6281288882120?text=halo%20mangkokku",
    },
  ];
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    // cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
    ],
  };

  const handleWhatsAppMe = (link) => link && window.open(link, "_blank");

  return (
    <Slider {...settings}>
      {logos.map((res, i) => (
        <div
          className="text-center d-flex justify-content-center ukmslideitem"
          key={i}
        >
          {/* <img src={res.logo} className=' ' alt="" /> */}
          <motion.div
            onClick={() => handleWhatsAppMe(res.link)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className="wrapwa pointer"
          >
            <img className="ukmlogo" src={res.logo} alt="logo" />
            {res.link && (
              <div className="wame">
                <BsWhatsapp color="white" size={18} />
              </div>
            )}
          </motion.div>
        </div>
      ))}
    </Slider>
  );
}
