import React from 'react'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'
import { BsInstagram, BsLinkedin } from "react-icons/bs";

import balesinLogo from '../../../images/balesinLogo.png'



export default function Footer() {
    const linkBox1 = [
        { link: '/privacy', label: 'Kebijakan Privasi' },
        { link: '/term', label: 'Syarat & Ketentuan' },
        // { link: '/', label: 'Blog' },
    ]

    const socialArr = [
        {
            link: 'https://www.instagram.com/balesin.id/',
            colorCode: '#c32aa3',
            icon: <BsInstagram size={25} className='' />,
            id: 'ig'
        },
        {
            link: 'https://www.linkedin.com/company/bjtech/',
            colorCode: '#0a66c2',
            icon: <BsLinkedin size={25} className='' />,
            id: 'linkedin'
        },
        // {
        //     link: 'https://twitter.com/balesin_id',
        //     colorCode: '#1da1f2',
        //     icon: <BsTwitter size={25} className='me-3' />,
        //     id: 'twitter'
        // },
    ]

    return (
        <div className="footer bg-white position-relative"
        // data-aos="fade-up"
        // // data-aos-duration=" "
        // data-aos-offset="0"
        >
            <div className='container py-4 py-sm-5'>
                <div className="d-flex justify-content-between flex-wrap wrapbox">
                    <div className="box">
                        <Link to='/'>
                            <img src={balesinLogo} className='logo' alt="" />
                        </Link>
                    </div>
                    <div className="box box-link">
                        <div className="links">
                            {
                                linkBox1.map((res, i) => <p><Link className='text-dark animlink' key={i} to={res.link}>{res.label}</Link></p>)
                            }
                        </div>

                        <hr className='d-block d-sm-none mb-0 px-3' />
                    </div>
                    <div className="box box-address">
                        <p>
                            <b>PT Jualan Online Indonesia</b> <br />
                            Perkantoran Permata Senayan B-25 Jl. Tentara Pelajar No. 1, Grogol Utara, Kebayoran Lama, Jakarta
                        </p>
                    </div>
                    <div className="box box-social">
                        <p> <a className='text-dark animlink' href="mailto:halo@balesin.id">halo@balesin.id</a></p>
                        <p><a className='text-dark animlink' href="tel:+6282123610173">+62 821 2361 0173</a></p>
                        <div className="social d-flex align-items-center mt-3">
                            {
                                socialArr.map((res, i) =>
                                    <a
                                        key={i}
                                        className='text-dark'
                                        href={res.link}
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        <motion.div
                                            whileHover={{ scale: 1.1, color: res.colorCode }}
                                        >
                                            {res.icon}
                                        </motion.div>
                                    </a>)
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
