import React from 'react'
import Layout from '../shared/components/Layout'
import BalesinSection from './components/BalesinSection'
import Client from './components/Client'
import Features from './components/Features'
import Jumbotron from './components/Jumbotron'
import MediaCoverage from './components/MediaCoverage'
import Our360 from './components/Our360'
import OurStory from './components/OurStory'
import ReachUs from './components/ReachUs'
import SolutionPartner from './components/SolutionPartner'

export default function HomeContainer() {

    return (
        <Layout>
            <div className='home'>
                <Jumbotron />
                <Client />
                <BalesinSection />
                <Our360 />
                <Features />
                <OurStory />
                <ReachUs />
                <SolutionPartner />
                <MediaCoverage />
            </div>
        </Layout>
    )
}
