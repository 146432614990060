import React from 'react'
import { MdCheckCircleOutline } from 'react-icons/md'
import doublePhone from '../../../images/featureSection/doublePhone.png'

import wa from '../../../images/socialBalesin/wa.png'
import line from '../../../images/socialBalesin/line.png'
import telegram from '../../../images/socialBalesin/telegram.png'
import fbm from '../../../images/socialBalesin/fbm.png'
import link from '../../../images/socialBalesin/link.png'


export default function BalesinSection() {
    const data = [
        'Improving merchants profitability',
        'Convert more data for customer insights',
        'Marketing and customer care'
    ]
    const socialLogo = [
        wa,
        line,
        telegram,
        fbm,
        link,
    ]
    return (
        <section className='balesin-section bg-light py-main position-relative'>
            <div className="container py-main">
                <div className="box d-flex justify-content-between align-items-center">
                    <div className="wrapimg">
                        <img src={doublePhone}
                            className='phoneimg'
                            alt="phone"
                            data-aos="zoom-in"
                            // data-aos-duration="1000"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-offset='100'
                        />
                        <div className="d-flex align-items-center d-block d-md-none px-5">
                            <p className='avail m-0 mt-3 me-2'>AVAILABLE ON VARIETY OF CHANNELS</p>
                            <div className='d-flex align-items-center mt-3 '>
                                {
                                    socialLogo.map((res, i) => <div className='wrapsoc'><img className='soclogo' key={i} src={res} alt="" /></div>)
                                }
                            </div>

                        </div>
                    </div>
                    <div className="wraplist px-5 px-md-3" data-aos="zoom-in">
                        <p className="title">
                            <b>Balesin.id</b> makes chat commerce experience better than marketplace.
                        </p>
                        <p className="desc">
                            Together we grow successful <b> Online Food Delivery chat commerce</b> </p>
                        <ul>
                            {
                                data.map((res, i) => (
                                    <li key={i} className='d-flex align-items-center'>
                                        <MdCheckCircleOutline size={22} color='green' className='me-2' />
                                        {res}
                                    </li>
                                ))
                            }
                            <hr className='m-0 d-none d-md-block' />
                        </ul>
                        <div className="d-flex align-items-center flex-wrap d-none d-md-block">
                            <p className='avail m-0 mt-3 me-3'>AVAILABLE ON VARIETY OF CHANNELS</p>
                            <div className='d-flex align-items-center mt-3 '>
                                {
                                    socialLogo.map((res, i) => <div className='wrapsoc'><img className='soclogo' key={i} src={res} alt="" /></div>)
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
