import React, { useEffect, useState } from 'react'
import { MdCheckCircleOutline } from "react-icons/md";


export default function FeaturesBox({ data, titleLogo, phoneImg, isReverse, desc, bgColor, animation, isHiddenOverflow, index }) {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 700 ? true : false); // 

    const handleWindowSizeChange = () => {
        const width = window.innerWidth < 700 ? true : false;
        setIsMobile(width)
        // console.log('render')
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
    }, []);


    return (

        <div
            className={`featuresbox d-flex justify-content-between align-items-center ${isReverse && 'flex-row-reverse'}`}
            style={{ background: bgColor }}
        >
            {/* <h1>{JSON.stringify(isMobile)}</h1> */}

            <div className={`wraplist ${isReverse ? 'ms-0 ms-md-3 ms-lg-0' : 'me-0 me-md-3 me-lg-3'}`}>
                <img src={titleLogo} alt="" className="logoTitle mb-3" />
                <p className="desc">{desc}</p>
                <ul className='d-none d-md-block'>
                    {
                        data.map((res, i) => (
                            <li key={i} className='d-flex align-items-center'>
                                <MdCheckCircleOutline size={22} color='green' className='me-2' />
                                <p className="m-0" dangerouslySetInnerHTML={{ __html: res }} />
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className={`wrapimg wrapimg-${index}`}>
                <img src={phoneImg}
                    className={`phoneimg phoneimg-${index}`} alt=""
                    // data-aos-anchor-placement="center-bottom"
                    data-aos-anchor-placement={isMobile ? "top-bottom" : "center-bottom"}
                    data-aos-offset="150"
                    data-aos={animation}
                />
                {/* mobile show */}
                <ul className='d-block d-md-none'>
                    {
                        data.map((res, i) => (
                            <li key={i} className='d-flex align-items-center'>
                                <MdCheckCircleOutline size={22} color='green' className='me-2' />
                                <p className="m-0" dangerouslySetInnerHTML={{ __html: res }} />
                            </li>
                        ))
                    }
                </ul>
                {/* mobile show end */}

            </div>
        </div>
    )
}
