import React from 'react'

import commerceIcon from '../../../images/ourStory/commerceIcon.svg'
import customerIcon from '../../../images/ourStory/customerIcon.svg'
import marketingIcon from '../../../images/ourStory/marketingIcon.svg'

// import CountUp from 'react-countup';

import commerceLogoBubleWhite from '../../../images/featureSection/commerceLogoBubleWhite.png'


import marketingLogo from '../../../images/featureSection/marketingLogo.png'


import customerLogo from '../../../images/featureSection/customerLogo.png'



export default function OurStory() {

    const data = [
        {
            icon: commerceIcon,
            bgColor: '#FFDD00',
            color: '#206AEC',
            name: commerceLogoBubleWhite,
            list: [
                { val: '54', after: '%', desc: 'TRANSACTION GROWTH WITHIN 7 MONTHS' },
                { val: '70', after: '', desc: 'AVERAGE CHAT PER SECOND' },
            ]
        },
        {
            icon: marketingIcon,
            bgColor: '#FFED7E',
            color: '#E5452D',
            name: marketingLogo,
            list: [
                { val: '1', after: 'mio ++', desc: 'LEADS' },
                { val: '590', after: 'K ++', desc: 'VOUCHER REDEEMED' },
                { val: '50', after: '%', before: `> `, desc: 'BROADCAST OPEN RATE' },
            ]
        },
        {
            icon: customerIcon,
            bgColor: '#FFF6BE',
            color: '#41A944',
            name: customerLogo,
            list: [
                { val: '1', after: 'mio', desc: 'CHAT PER CONVERSATION' },
                { val: '95.5', after: '%', desc: 'AVERAGE SUCCESS RATE' },
            ]
        },
    ]


    return (
        <section className='ourstory py-main bg-light position-relative'>
            <h2 className='text-center'
                data-aos="fade-in"
            // data-aos-duration='1500'
            // data-aos-offset='650'
            >
                OUR STORY
            </h2>
            <div className="container">
                <div className="row row-lg-cols-3">
                    {
                        data.map((res, i) => (

                            <div key={i} className="col mb-4 mb-sm-0">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="wrapicon mb-4" style={{ background: res.bgColor }}>
                                        <img src={res.icon} alt="" data-aos="zoom-in" data-aos-offset='50' />
                                    </div>
                                    <img className='name mb-4' src={res.name} alt="" />
                                    {
                                        res.list.map((item, j) => (
                                            <div className="box text-center" key={j} data-aos="fade-up">
                                                <div className="value d-flex align-items-center justify-content-center">
                                                    <div data-aos="zoom-in">
                                                        {item.before}
                                                    </div>
                                                    {/* <div >
                                                        <CountUp
                                                            end={item.val}
                                                            start={item.val - 100}
                                                            enableScrollSpy
                                                            duration={3}
                                                            // useEasing={true}
                                                            scrollSpyOnce
                                                        />
                                                    </div> */}
                                                    {/* <div> */}
                                                    {item.val}
                                                    {/* </div> */}
                                                    <div data-aos="zoom-in">
                                                        {item.after}
                                                    </div>
                                                </div>
                                                <p className="desc">{item.desc}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    (i + 1) !== data.length &&
                                    <hr className='m-0 d-block d-sm-none' />
                                }
                            </div>
                        ))
                    }
                </div>
            </div>

        </section>
    )
}
