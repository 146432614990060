import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';

import routes from './app/routes';
import './styles/App.scss';
import Aos from 'aos';
import 'aos/dist/aos.css';





function App() {
  const location = useLocation()
  Aos.init();
  Aos.refreshHard();

  const TRACKING_ID = "UA-144951444-1"; // TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  
  ReactGA.set({
    userId: localStorage.getItem('userName'),
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">

      <AnimatePresence mode='wait'>
        <Routes key={location.pathname} location={location}>
          {
            routes.map((res, i) => (
              <Route
                key={i}
                path={res.path}
                element={res.element}
              />
            ))
          }
          <Route path='*' element={<div>404 page not found</div>} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
