import ContactContainer from '../features/contact/ContactContainer';
import HomeContainer from '../features/home/HomeContainer';
import PrivacyContainer from '../features/privacy/PrivacyContainer';
import TermContainer from '../features/term/TermContainer';

export default [
    {
        path: '/',
        element: <HomeContainer />,
    },
    {
        path: '/contact',
        element: <ContactContainer />,
    },
    {
        path: '/privacy',
        element: <PrivacyContainer />,
    },
    {
        path: '/term',
        element: <TermContainer />,
    },
]