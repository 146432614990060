import React from 'react'
import Layout from '../shared/components/Layout'

export default function ContactContainer() {
    return (
        <Layout>
            <div className='container'>
                <h1>CONTACT</h1>
            </div>
        </Layout>
    )
}
