import React from 'react'

import imageRocket from '../../../images/imageRocket.png'


export default function ReachUs() {
    return (
        <section className='reachus'>
            <div className="container text-center d-flex justify-content-center align-items-center flex-wrap h-100 wrapcon">
                <div>
                    <h2>Accelerate your business process with balesin.id, let us accompany your digital journey experience</h2>
                    <button
                        className="btn-primary bg-white"
                        data-aos="fade-up"
                        // data-aos-offset='650'
                        onClick={() => window.location.href = 'mailto:halo@balesin.id'}
                    >
                        REACH US NOW!
                    </button>
                </div>
                <img src={imageRocket} alt="" className="rocket" data-aos="fade-up" data-aos-delay='500' data-aos-duration='1000' />
            </div>
        </section>
    )
}
