import React from 'react'

import FeaturesBox from './FeaturesBox';

import commerceLogo from '../../../images/featureSection/commerceLogo.png'
import commercePhone from '../../../images/featureSection/commercePhone.png'

import marketingLogo from '../../../images/featureSection/marketingLogo.png'
import marketingPhone from '../../../images/featureSection/marketingPhone.png'

import customerLogo from '../../../images/featureSection/customerLogo.png'
import customerPhone from '../../../images/featureSection/customerPhone.png'






export default function Features() {
    const arrCommerce = [
        'Cheaper Platform Fee',
        'Payments & Logistics Integration',
        'WhatsApp Business API',
        // 'API API Integration (POS Systems, etc)',
        // 'Analytic & Store Dashboard',
        'Order Management System Apps',
    ]

    const arrMarketing = [
        'Discount Pricing', 'Voucher', 'Events', 'Broadcast', 'Adverstisement'
    ]

    const arrCustomer = [
        'Customizable FAQ Library',
        'Support Contextual FAQ',
        'Hybrid <b> Chatbot - Live Agent </b> System',
        'Live Agent Dashboard and Analytics'
    ]

    const featureArr = [
        {
            bgColor: '#FFDD00',
            titleLogo: commerceLogo,
            desc: 'Chat automation for buying and selling products',
            phoneImg: commercePhone,
            animation: 'zoom-in-left',
            data: arrCommerce
        },
        {
            bgColor: '#FFED7E 40%',
            isReverse: true,
            titleLogo: marketingLogo,
            desc: 'Reach your target efficiently through automation.',
            phoneImg: marketingPhone,
            animation: 'zoom-in-right',
            data: arrMarketing,
        },
        {
            bgColor: '#FFF6BE 40%',
            titleLogo: customerLogo,
            desc: 'Automate answers for repeated questions fast and precise.',
            phoneImg: customerPhone,
            data: arrCustomer,
            animation: 'fade-up',
            isHiddenOverflow: true,
        },

    ]

    return (
        <section className='features py-main bg-white'>
            {
                featureArr.map((res, i) => (
                    <div className="container wrapfeatcon " key={i}>
                        <FeaturesBox
                            index={i}
                            data={res?.data}
                            titleLogo={res?.titleLogo}
                            phoneImg={res?.phoneImg}
                            isReverse={res?.isReverse}
                            desc={res?.desc}
                            bgColor={res?.bgColor}
                            animation={res?.animation}
                            isHiddenOverflow={res?.isHiddenOverflow}
                        />
                    </div>

                ))
            }
        </section>
    )
}
