import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { BsWhatsapp } from 'react-icons/bs'
import AnimateWrapper from './AnimateWrapper'
import Footer from './Footer'
import Navbar from './Navbar'

export default function Layout({ children }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const floatingWhatsApp = () => (
        <a href="https://wa.me/6282113006947?text=Hi%20Balesin" target='_blank'>
            <motion.div className="floatwa"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
                <BsWhatsapp color='white' size={30} />
            </motion.div>
        </a>
    )

    return (
        <div className='layout'>
            <Navbar />
            <AnimateWrapper>
                {children}
                {floatingWhatsApp()}
                <Footer />
            </AnimateWrapper>
        </div>
    )
}
