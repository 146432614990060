import React from "react";
import line from "../../../images/partnerLogos/line.png";
import power from "../../../images/partnerLogos/power.png";
import xendit from "../../../images/partnerLogos/xendit.png";
import midtrans from "../../../images/partnerLogos/midtrans.png";
// import sirclo from '../../../images/partnerLogos/sirclo.png'
import jatis from "../../../images/partnerLogos/jatis.png";
import pitjarus from "../../../images/partnerLogos/pitjarus.png";
import wappin from "../../../images/partnerLogos/wappin.png";
import uib from "../../../images/partnerLogos/uib.png";
import wa from "../../../images/partnerLogos/wa.png";
import googlestartup from "../../../images/partnerLogos/googlestartup.png";
import meta from "../../../images/partnerLogos/meta.png";
import gcp from "../../../images/partnerLogos/gcp.png";
// import chatgpt from "../../../images/partnerLogos/chatgpt.png";

export default function SolutionPartner() {
  const arrLogo = [
    googlestartup,
    gcp,
    meta,
    wa,
    line,
    // chatgpt,
    power,
    xendit,
    midtrans,
    // sirclo,
    jatis,
    pitjarus,
    wappin,
    uib,
  ];

  return (
    <section className="solution-partner container py-main bg-white position-relative">
      <h2
        className="text-center mb-3"
        data-aos="fade-up"
        // data-aos-offset="0"
      >
        SOLUTION PARTNERS
      </h2>
      <p className="text-center mb-0 desc" data-aos="fade-up">
        Collaborate with credible partners to provide end-to-end solution
      </p>
      <div className="d-flex wraplogos">
        {arrLogo.map((res, i) => (
          <div
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-easing="linear"
            data-aos-offset="0"
            className="figlogo d-flex justify-content-center align-items-center "
            key={i}>
            <img className="" src={res} alt="logo" />
          </div>
        ))}
        {[1, 1, 1, 1, 1].map((res, i) => (
          <div key={i} className="figlogo m-0"></div>
        ))}
      </div>
    </section>
  );
}
