import React from 'react'
import Slider from 'react-slick';

//new
import swa from '../../../images/logoMedia/swa.png'
import media from '../../../images/logoMedia/media.png'
import jakpos from '../../../images/logoMedia/jakpos.png'
import liputan6 from '../../../images/logoMedia/liputan6.png'
import detiknet from '../../../images/logoMedia/detiknet.png'





export default function MediaCoverage() {
    const logos = [
        { logo: swa, link: "https://swa.co.id/swa/trends/technology/balesin-id-bantu-merek-maksimalkan-whatsapp-sebagai-kanal-penjualan" },
        { logo: detiknet, link: "https://inet.detik.com/business/d-5213525/balesinid-tawarkan-chatbot-untuk-jualan-online-lewat-whatsapp" },
        { logo: jakpos, link: "https://www.thejakartapost.com/life/2020/05/06/retail-businesses-survival-strategy-might-rely-on-chat-commerce.html" },
        { logo: liputan6, link: "https://www.liputan6.com/tekno/read/4382426/balesinid-kembangkan-fitur-chat-commerce-untuk-whatsapp" },
        { logo: media, link: "https://mediaindonesia.com/teknologi/353093/bjtech-kembangkan-inovasi-belanja-hanya-lewat-chatting" },
    ]
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 1000,
        // cssEase: "linear",
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };
    return (
        <section className="media-coverage py-main bg-light position-relative">
            <div className="container">
                <h2 className='text-center mb-4 mb-md-5'>MEDIA COVERAGE</h2>
                <Slider {...settings}>
                    {
                        logos.map((res, i) => (
                            <div className="text-center d-flex justify-content-center py-2" key={i}>
                                <a href={res.link} target='_blank' rel="noreferrer">
                                    <img src={res.logo} className='img-fluid medialogo' alt="" />
                                </a>
                            </div>
                        ))
                    }
                </Slider>
                {/* <div className="d-flex justify-content-around align-items-center">
                    {
                        logos.map((res, i) => (
                            <div className="text-center d-flex justify-content-center" key={i}>
                                <a href={res.link} target='_blank'>
                                    <img src={res.logo} className='img-fluid medialogo' alt="" />
                                </a>
                            </div>
                        ))
                    }
                </div> */}
            </div>
        </section>

    )
}
