export const termWellcome = {
    title: `<h6>Selamat datang di BALESIN by BJtech.</h6>`,
    content: `
    <p>Syarat dan ketentuan berikut mengatur pemakaian jasa yang ditawarkan oleh PT Jualan Online Indonesia (BJtech) terkait penggunaan situs BALESIN. Pengguna disarankan membaca Syarat &amp; Ketentuan dengan seksama sebelum menggunakan layanan BJtech karena dapat berdampak kepada hak dan kewajiban pengguna di bawah hukum.&nbsp;</p>
    <p>Dengan mendaftar dan/atau menggunakan situs BALESIN, maka pengguna dianggap telah membaca, mengerti, memahami, dan menyetujui semua isi yang tertuang dalam Syarat &amp; Ketentuan. Syarat &amp; Ketentuan ini merupakan bentuk kesepakatan yang sah antara pengguna dan PT Jualan Online Indonesia (BJtech).</p>
    <p>Pengguna dipersilakan untuk tidak menggunakan layanan BALESIN jika tidak menyetujui salah satu, sebagian, atau seluruh isi dan tidak menyetujui untuk terikat dengan Syarat &amp; Ketentuan. BJtech berhak membuat perubahan, perbaikan, atau kebijakan mengenai Syarat &amp; Ketentuan di kemudian hari.</p>`
}

export const termAccordion = [
    {
        title: `<h6>DEFINISI</h6>`,
        content: `
        <p>1. PT Jualan Online Indonesia (BJtech) adalah suatu perseroan terbatas yang menyediakan sebuah platform pembuatan chatbot untuk membantu pelaku usaha atau bisnis dalam menunjang operasional bisnis online. Selanjutnya disebut BALESIN.</p>
        <p>2. &nbsp;Situs BALESIN adalah platform.bjtech.io/balesin</p>
        <p>3. BALESIN adalah layanan gratis dan berbayar* di mana pengguna bisa melakukan transaksi jual beli secara otomatis dengan menggunakan chatbot. Chatbot adalah teknologi berbasis kecerdasan buatan yang mampu menirukan&nbsp;percakapan&nbsp;manusia melalui obrolan teks (chatting) secara&nbsp;otomatis. BALESIN bukan merupakan marketplace.</p>
        <p>4. Syarat &amp; Ketentuan adalah perjanjian antara pengguna dan BJtech yang berisikan seperangkat peraturan yang mengatur hak, kewajiban, tanggung jawab pengguna dan BJtech, serta tata cara dan panduan penggunaan sistem layanan BALESIN.</p>
        <p>5. Pengguna adalah pihak yang bergabung dan menggunakan layanan BALESIN, baik perseorangan maupun UKM/bisnis.</p>
        `
    },
    {
        title: `<h6>AKUN, PASSWORD, DAN KEAMANAN</h6>`,
        content: `
        <p>Pengguna bertanggung jawab secara pribadi untuk menjaga kerahasiaan akun dan password untuk semua aktivitas yang terjadi dalam akun pengguna. BJtech tidak akan meminta username maupun password milik akun pengguna untuk alasan apapun, oleh karena itu BJtech menghimbau pengguna agar tidak memberikan data tersebut maupun data penting lainnya kepada pihak yang mengatasnamakan BJtech atau pihak lain yang tidak dapat dijamin keamanannya.</p>
        <p>Pengguna dengan ini menyatakan bahwa BJtech tidak bertanggung jawab atas kerugian ataupun kendala yang timbul atas penyalahgunaan akun pengguna yang diakibatkan kelalaian pengguna, termasuk namun tidak terbatas pada meminjamkan atau memberikan akses akun kepada pihak lain.</p>
        `
    },
    {
        title: `<h6>PRODUK DAN KONTEN</h6>`,
        content: `
        <p>Dalam menggunakan setiap fitur dan/atau layanan BALESIN, pengguna memahami dan menyetujui bahwa segala jenis produk yang diunggah adalah tanggung jawab pengguna sepenuhnya. Pengguna dilarang untuk mengunggah atau menggunakan kata-kata, komentar, gambar, atau konten apapun yang mengandung unsur SARA, diskriminasi, merendahkan atau menyudutkan orang lain, vulgar, bersifat ancaman, atau hal-hal lain yang dapat dianggap tidak sesuai dengan nilai dan norma sosial, menyalahgunakan Hak Cipta, ataupun yang melanggar ketentuan hukum yang berlaku di Indonesia.</p>
        <p>BJtech tidak bisa memantau jenis produk yang diperjual belikan dan tercatat di BALESIN, maka dari itu segala bentuk penyalahgunaan produk, hak cipta, ataupun yang melanggar ketentuan hukum adalah di luar tanggung jawab BJtech.</p>
        `
    },
    {
        title: `<h6>TRANSAKSI DAN PENJUALAN</h6>`,
        content: `
        <p>Pengguna yang menggunakan layanan BALESIN untuk kegiatan jual beli harus memenuhi beberapa ketentuan sebagai berikut:</p>
        <p>1. Pengguna wajib memberikan foto dan informasi produk dengan lengkap dan jelas sesuai dengan kondisi dan kualitas produk yang dijualnya.</p>
        <p>2. Harga barang yang ditetapkan pengguna adalah harga yang wajar. Pengguna dilarang memanipulasi harga barang dengan cara apapun.</p>
        <p>3. Pengguna memahami dan menyetujui bahwa kesalahan foto atau ketik yang menyebabkan keterangan harga atau informasi lain menjadi tidak benar/sesuai adalah tanggung jawab pengguna. Perlu diingat dalam hal ini, apabila terjadi kesalahan pengetikan keterangan barang yang tidak disengaja, pengguna berhak menolak pesanan barang yang dilakukan oleh pembeli dan mengubah keterangan di dalam platform BALESIN.</p>
        <p>4. Segala transaksi dan/atau penjualan antara pengguna dan pembeli/pihak ketiga lainnya adalah di luar layanan BALESIN dan pengguna menyetujui bahwa pengguna bertanggung jawab atas setiap hal transaksi seperti pembayaran, return, pengembalian uang, dan pengiriman barang.</p>
        <p>5. Pengguna memahami dan menyetujui bahwa setiap masalah dan/atau perselisihan yang terjadi akibat ketidaksepahaman antara pengguna dan pembeli/pihak ketiga lainnya tentang transaksi/penjualan bukanlah merupakan tanggung jawab BJtech.</p>
        `
    },
    {
        title: `<h6>PENGGUNAAN AKUN</h6>`,
        content: `
        <p>Pengguna wajib mematuhi segala Syarat &amp; Ketentuan yang telah ditetapkan oleh BJtech. BJtech berhak menonaktifkan akun pengguna tanpa pemberitahuan terlebih dahulu apabila menemukan kelanggaran yang dilakukan oleh pengguna. Dengan menggunakan akun BALESIN, pengguna berjanji untuk tidak:</p>
        <p>1. Menggunakan layanan BALESIN untuk segala tindak atau tujuan penipuan ataupun kejahatan lainnya.</p>
        <p>2. Menggunakan layanan BALESIN untuk menyamar, mengaku-aku, atau memalsukan sebagai identitas lain ataupun terafiliasi dengan pihak lain.</p>
        <p>3. Mencoba untuk meretas, membongkar, atau menggunakan bagian apapun dari BALESIN. Mempengaruhi, memanipulasi jaringan server BALESIN yang dapat mengganggu dan/atau menghambat aktivitas pengguna lainnya.</p>
        <p>4. Mengumpulkan data atau menyebarluaskan informasi yang bersifat pribadi dari pemegang akun lain ataupun customer/pembeli dan menyalahgunakannya.</p>
        <p>5. Menggunakan layanan BALESIN untuk membuat, mengirimkan, dan/atau menyebarluaskan konten tidak layak atau melanggar aturan hukum yang berlaku, SARA, kekerasan, ancaman, hasutan, fitnah, pelecehan, vulgar, berbahaya secara fisik ataupun non-fisik, melanggar norma sosial, serta merugikan orang lain.</p>
        <p>6. Menggunakan layanan BALESIN untuk melakukan penjualan dan/atau transaksi produk yang tidak diizinkan dan melanggar undang-undang/aturan hukum yang berlaku. Termasuk di anatranya obat-obatan terlarang dan illegal, narkotika, konten pornografi, manusia dan organ manusia, senjata api, bahan peledak, zat kimia berbahaya, jual beli barang hasil curian, data-data yang berhubungan dan dapat mengganggu stabilitas negara, dan barang-barang lain yang merugikan orang lain.</p>
        `
    },
    {
        title: `<h6>PEMBERHENTIAN AKUN</h6>`,
        content: `
        <p>Pengguna yang menggunakan layanan platform BALESIN adalah pengguna yang sudah mengetahui proses dan Syarat &amp; Ketentuan yang berlaku sebagai mestinya. Oleh karena itu, BJtech berhak memberhentikan akun pengguna jika ditemukan terdapat kecurangan, penipuan, atau penyalahgunaan akun yang dilakukan, baik kepada customer, pihak lain, maupun manajemen BJtech.</p>
        `
    },
    {
        title: `<h6>PELEPASAN</h6>`,
        content: `
        <p>Jika pengguna memiliki perselisihan dengan satu atau lebih pengguna lainnya, pengguna melepaskan BJtech (termasuk Induk Perusahaan, Direktur, dan karyawan) dari klaim dan tuntutan atas kerusakan dan kerugian (aktual dan tersirat) dari setiap jenis dan sifatnya, yang dikenal dan tidak dikenal, yang timbul dari atau dengan cara apapun berhubungan dengan sengketa tersebut. Dengan demikian maka pengguna dengan sengaja melepaskan segala perlindungan hukum (yang terdapat dalam undang-undang atau peraturan hukum yang lain) yang akan membatasi cakupan ketentuan pelepasan ini.</p>
        `
    },
    {
        title: `<h6>GANTI RUGI</h6>`,
        content: `
        <p>Pengguna akan melepaskan BJtech dari tuntutan ganti rugi dan menjaga BJtech (termasuk Induk Perusahaan, direktur, dan karyawan) dari setiap klaim atau tuntutan, termasuk biaya hukum yang wajar, yang dilakukan oleh pihak ketiga yang timbul dalam hal pengguna melanggar perjanjian ini, penggunaan layanan BJtech yang tidak semestinya dan/ atau pelanggaran pengguna terhadap hukum atau hak-hak pihak ketiga.</p>
        `
    },
    {
        title: `<h6>PILIHAN HUKUM</h6>`,
        content: `
        <p>Perjanjian ini akan diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia, tanpa memperhatikan pertentangan aturan hukum. Anda setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara apapun berhubungan dengan situs dan/atau Perjanjian ini akan diselesaikan secara eksklusif dalam yurisdiksi pengadilan Republik Indonesia.</p>
        `
    },
    {
        title: `<h6>PEMBARUAN SYARAT &amp; KETENTUAN</h6>`,
        content: `
        <p>Dengan ini, pengguna telah menyatakan bahwa pengguna adalah orang yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah perjanjian yang sah menurut hukum. BJtech memiliki kewenangan untuk menutup akun pengguna, baik sementara maupun permanen apabila didapati adanya tindakan kecurangan atau pelanggaran Syarat &amp; Ketentuan dan/atau hukum yang berlaku.</p>
        <p>Syarat &amp; ketentuan dapat diubah dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan sebelumnya. BJtech menyarankan agar pengguna membaca secara seksama dan memeriksa halaman Syarat &amp; ketentuan ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan layanan&nbsp;BALESIN, maka pengguna dianggap menyetujui perubahan-perubahan dalam Syarat &amp; ketentuan.</p>
        `
    },
    {
        title: `<h6>ADA PERTANYAAN LAINNYA?</h6>`,
        content: `
        <p>Jika kamu memiliki pertanyaan lain, kamu bisa menghubungi team BJtech di&nbsp;<a href="mailto:admin@bjtech.io">admin@bjtech.io</a>.</p>
        `
    },
] 